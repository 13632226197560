import { Divider, FormGroup, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DateTime } from "luxon";
import { Trans, useTranslation } from "react-i18next";
import { ContactForm } from "./common/ContactForm";
import { Helmet } from "react-helmet";
import ContentWithFooter from "./layouts/ContentWithFooter";
import React from "react";
import { pageTitle } from "../settings";
import { isMobile } from "../utils/utils";

const CHANGE_LOG_LIST = [
  {
    date: 1726689513127,
    name: "create_budget",
    version: "1.0.40",
    intent: Intent.NONE,
    icon: IconNames.DOLLAR,
  },
  {
    date: 1726642131142,
    name: "export_data",
    version: "1.0.39",
    intent: Intent.DANGER,
    icon: IconNames.LAB_TEST,
  },
  {
    date: 1722863380872,
    name: "chart_fixes",
    version: "1.0.21",
    intent: Intent.NONE,
    icon: IconNames.TICK,
  },
  {
    date: 1722777860619,
    name: "resetting_budget",
    version: "1.0.20",
    intent: Intent.NONE,
    icon: IconNames.TICK,
  },
  {
    date: 1722567850519,
    name: "minor_updates",
    version: "1.0.19",
    intent: Intent.NONE,
    icon: IconNames.TICK,
  },
  {
    date: 1721550433037,
    name: "fixes_category_page_total_value_bug",
    version: "1.0.18",
    intent: Intent.NONE,
    icon: IconNames.TICK,
  },
  {
    date: 1721299974672,
    name: "fixes_logo",
    version: "1.0.17",
    intent: Intent.NONE,
    icon: IconNames.TICK,
  },
  {
    date: 1721286156097,
    name: "added_changelog",
    version: "1.0.16",
    intent: Intent.NONE,
    icon: IconNames.TICK,
  },
];

const Changelog = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet>
        <title>Changelog - {pageTitle}</title>
      </Helmet>
      <ContentWithFooter>
        <div className="flex flex-col md:flex-row max-w-7xl mx-auto">
          <div className="flex-1">
            <h2 className="bp5-heading pt-12 pb-6 text-center">{t("labels.changelog")}</h2>
            <ul className="text-lg mb-2 changelog-list">
              {CHANGE_LOG_LIST.map((item, idx) => {
                const i = t(`changelog.${item.name}`, { returnObjects: true }) as {
                  title: string;
                  description: string;
                };
                return (
                  <li key={item.name} className="mb-4">
                    <h3>
                      <Icon
                        size={isMobile() ? 20 : 30}
                        icon={item.icon}
                        intent={item.intent}
                        className="mr-2"
                      />{" "}
                      <strong>{item.version}</strong> -{" "}
                      {DateTime.fromMillis(item.date).toLocaleString()}
                      <i className="ml-4">{i.title}</i>
                    </h3>
                    <div
                      className="ml-10 mt-2"
                      dangerouslySetInnerHTML={{ __html: i.description }}
                    ></div>
                    {idx !== CHANGE_LOG_LIST.length - 1 && <Divider className="my-12" />}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="flex-1">
            <div>
              <h2 className="bp5-heading pt-12 pb-6 text-center">{t("labels.feedback")}</h2>
              <FormGroup
                helperText={
                  <div className="text-center">
                    <Trans i18nKey="feedback.text">
                      <strong className="block">
                        Please take a moment to share your thoughts and suggestions with us.
                      </strong>
                      Your feedback helps us improve our services and provide a better experience
                      for you.
                    </Trans>
                  </div>
                }
              ></FormGroup>
            </div>
            <ContactForm />
          </div>
        </div>
      </ContentWithFooter>
    </React.Fragment>
  );
};

export default Changelog;
